/* Poppins */

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(assets/fonts/poppins/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  src: url(assets/fonts/poppins/Poppins-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url(assets/fonts/poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
}

/* End Poppins */
